import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useColorMode,
} from "@chakra-ui/react"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import hamburgerW from "../img/menu-white.png"
import hamburger from "../img/menu.png"
import { default as logo, default as logoW } from "../img/ONF_logo.png"

const Banner = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const btnRef = React.useRef()
  const cfMenu = useStaticQuery(
    graphql`
      query {
        allContentfulHovedmeny {
          edges {
            node {
              meny {
                menu {
                  label
                  link
                  subMenu {
                    label
                    link
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const fallbackMenu = [
    { label: <FormattedMessage id="headerMenuAbout" />, link: "/om-oss" },
    {
      label: <FormattedMessage id="headerMenuStyret" />,
      link: "/hovedstyret",
      subMenu: [
        {
          label: "Styremedlemmer",
          link: "/styret",
        },
        {
          label: "Generalforsamling",
          link: "/generalforsamling",
        },
        // {
        //   label: "Aksjonsplan",
        //   link: "/aksjonsplan",
        // },
      ],
    },
    {
      label: <FormattedMessage id="headerMenuFaggrupper" />,
      link: null,
      subMenu: [
        {
          label: "Bygg og anlegg",
          link: "/faggruppe-bygg-og-anlegg",
        },
        {
          label: "Handel og service",
          link: "/faggruppe-handel-og-service",
        },
        {
          label: "Reiseliv",
          link: "/faggruppe-reiseliv",
        },
        {
          label: "Skifer",
          link: "/faggruppe-skifer",
        },
      ],
    },
    { label: <FormattedMessage id="headerMenuMembers" />, link: "/medlemmer" },
    {
      label: <FormattedMessage id="headerMenuSignUp" />,
      link: "/bli-medlem",
    },
    { label: <FormattedMessage id="headerMenuContact" />, link: "/contact" },
  ]
  const menuEdges = cfMenu?.allContentfulHovemeny?.edges
  const menu = menuEdges ? menuEdges[0].node.meny.menu : fallbackMenu
  const { colorMode } = useColorMode()
  const isLight = colorMode === "light"
  return (
    <header className="sc-header">
      <div>
        <Link to="/">
          <img className="header-logo" src={isLight ? logo : logoW} />
        </Link>
      </div>
      <div className="sc-nav-menu">
        {fallbackMenu.map((item, i) => (
          <div key={i}>
            {item.link ? (
              <Link
                to={item.link}
                className="header-hover"
                activeClassName="sc-nav-menu-item-active"
              >
                {item.label}
              </Link>
            ) : (
              <div className="header-hover">{item.label}</div>
            )}
            {item.subMenu && (
              <Box
                className="header-hover-submenu"
                background={isLight ? "white" : "gray.800"}
                boxShadow="md"
              >
                {item.subMenu?.map((subItem, j) => (
                  <div key={`sub-${j}`} className="header-hover-submenu-item">
                    <Link
                      to={subItem.link}
                      className="header-hover-submenu-item-link"
                      activeClassName="sc-nav-menu-subitem-active"
                    >
                      {subItem.label}
                    </Link>
                  </div>
                ))}
              </Box>
            )}
          </div>
        ))}
      </div>
      <div className="onf-nav-menu-mobile">
        <button onClick={() => setMenuOpen(!menuOpen)}>
          <img src={isLight ? hamburger : hamburgerW} alt="Menu"></img>
        </button>
        <Drawer
          isOpen={menuOpen}
          placement="right"
          onClose={() => setMenuOpen(false)}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay>
            <DrawerContent padding="20px">
              <DrawerCloseButton />
              <DrawerHeader>MENY</DrawerHeader>
              <DrawerBody
                overflowY="auto"
                className="onf-nav-menu-mobile-content"
              >
                {menu.map(item => (
                  <div key={item.label}>
                    {item.link ? (
                      <Link
                        to={item.link}
                        activeClassName="sc-nav-menu-item-active"
                      >
                        {item.label}
                      </Link>
                    ) : (
                      <div>{item.label}</div>
                    )}

                    {item.subMenu?.map(subItem => (
                      <div key={subItem.label} className="menu-dialog-sublink">
                        <Link
                          to={subItem.link}
                          activeClassName="sc-nav-menu-item-active"
                        >
                          - {subItem.label}
                        </Link>
                      </div>
                    ))}
                  </div>
                ))}
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </div>
    </header>
  )
}

Banner.propTypes = {
  title: PropTypes.string,
  ingress: PropTypes.string,
}

export default Banner
